<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="600px" scrollable transition="dialog-transition" @input="$emit('close-dialog')">
      <v-card class="pa-8">
        <v-row class="d-flex justify-end">
          <v-btn icon color="primary" @click="$emit('close-dialog')">
            <v-icon size="20">
              close
            </v-icon>
          </v-btn>
        </v-row>
        <v-row class="pa-4 d-flex justify-start">
          <v-icon color="primary" size="36">
            lightbulb_outline
          </v-icon>
        </v-row>

        <div class="py-4 v-text-title" v-text="$t('project.academy.users.addStaffModal')" />

        <v-combobox
          :value="form.emails"
          :label="$t('common.emails')"
          multiple
          deletable-chips
          append-icon=""
          :delimiters="[',',' ']"
          small-chips
          validate-on-blur
          outlined
          dense
          :error-messages="getFieldErrors('emails')"
          @change="form.emails = $event.reduce((acc, curr) => [...acc, ...curr.trim().toLowerCase().split(/[, ]+/)], [])"
          @blur="$v.form.emails.$touch()"
        />
        <v-select
          v-model="form.role"
          :items="roleItems"
          :label="$t('common.role')"
          outlined
          dense
          @blur="$v.form.role.$touch()"
        />

        <v-select
          v-if="form.role == 'coach'"
          v-model="form.teamIds"
          multiple
          :label="$t('common.team')"
          :items="teams"
          item-text="fullTeamName"
          item-value="id"
          outlined
          dense
          @blur="$v.form.teamIds.$touch()"
        />

        <v-card-actions class="pa-0 d-flex justify-center">
          <v-btn class="px-4" color="primary" :disabled="disabledInviteBtn" @click="inviteStaff" v-text="$t('common.sendInvitation')" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { email, requiredIf, required, minLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'AddStaffDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {
        role: null,
        teamIds: [],
        emails: [],
      },
    }
  },
  computed: {
    ...mapGetters({ teams: 'academy/teams' }),
    ...mapGetters({ staffRoles: 'projectUsers/staffRoles' }),
    roleItems({ staffRoles }) {
      return staffRoles.map(v => ({ value: v, text: this.$t(`user.roles.${v}`) }))
    },
    disabledInviteBtn({ form }) {
      return this.$v.form.$invalid
    },
  },
  validations() {
    return {
      form: {
        role: { required },
        emails: {
          required,
          minLength: minLength(1),
          $each: {
            required,
            email,
          },
        },
        teamIds: {
          required: requiredIf(() => this.form.role == 'coach'),
        },
      },
    }
  },
  methods: {
    inviteStaff() {
      const { form, organizationId, projectId } = this
      if (!this.isFormValid()) return
      this.runAsync(async () => {
        await Promise.all(
          form.emails.map(
            email => this.$store.dispatch(
              'projectUsers/addStaffer',
              { organizationId, projectId, role: form.role, teamIds: form.teamIds, email },
            ),
          ),
        )
        this.$emit('close-dialog')
      })
    },
  },
}
</script>
